import axios from "axios"

export const GetData = async (url, token = '') => {
    const headersData = {
        'Content-Type': 'application/json',
    }
    if (localStorage.token) {
        headersData.access_token = localStorage.token
    }
    return await axios
        .get(process.env.REACT_APP_API_URL + url, {
            headers: headersData
        })
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            if (error?.response?.status === 401 && localStorage.token) {
                setTimeout(() => {
                    localStorage.clear()
                    window.location.href = '/'
                }, 1000)
                return { success: false, message: '' }
            }
            if (error?.response?.status === 404) {
                return { success: false, message: error.message ? error.message : '404 api not found..' }
            }
            if (error?.response?.data) {
                return error?.response?.data
            } else {
                return { success: false, message: 'Internal server error.' }
            }
        })
}

export const PostData = async (url, data, tokenRequired = true) => {
    const headersData = {
        'Content-Type': 'application/json',
    }

    if (localStorage.token && url !== 'user/login' && tokenRequired === true) {
        headersData.access_token = localStorage.token
    }
    return await axios
        .post(process.env.REACT_APP_API_URL + url, data, {
            headers: headersData
        })
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            if (error?.response?.status === 401 && localStorage.token) {
                setTimeout(() => {
                    localStorage.clear()
                    window.location.href = '/'
                }, 1000)
                return { success: false, message: '' }
            }
            if (error?.response?.status === 404) {
                return { success: false, message: error?.response?.data?.message ? error?.response?.data?.message : 'Api not found' }
            }
            if (error?.response?.data) {
                return error?.response?.data
            } else {
                return { success: false, message: 'Internal server error.' }
            }
        })
}
export const PostFormData = async (url, data, tokenRequired = true) => {
    const headersData = {
        'Content-Type': 'multipart/form-data',
    }

    if (localStorage.token && url !== 'user/login' && tokenRequired === true) {
        headersData.access_token = localStorage.token
    }
    return await axios
        .post(process.env.REACT_APP_API_URL + url, data, {
            headers: headersData
        })
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            if (error?.response?.status === 401 && localStorage.token) {
                setTimeout(() => {
                    localStorage.clear()
                    window.location.href = '/'
                }, 1000)
                return { success: false, message: '' }
            }
            if (error?.response?.status === 404) {
                return { success: false, message: error?.response?.data?.message ? error?.response?.data?.message : 'Api not found' }
            }
            if (error?.response?.data) {
                return error?.response?.data
            } else {
                return { success: false, message: 'Internal server error.' }
            }
        })
}
export const PostDataLogIn = async (url, data, tokenRequired = true) => {
    const headersData = {
        'Content-Type': 'application/json',
    }

    if (localStorage.token && url !== 'user/login' && tokenRequired === true) {
        headersData.access_token = localStorage.token
    }
    return await axios
        .post(process.env.REACT_APP_API_URL + url, data, {
            headers: headersData
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            if (error?.response?.status === 401 && localStorage.token) {
                setTimeout(() => {
                    localStorage.clear()
                    window.location.href = '/'
                }, 1000)
                return { success: false, message: '' }
            }
            if (error?.response?.status === 404) {
                return { success: false, message: error?.response?.data?.message ? error?.response?.data?.message : 'Api not found' }
            }
            if (error?.response?.data) {
                return error?.response?.data
            } else {
                return { success: false, message: 'Internal server error.' }
            }
        })
}

export const DeleteData = async (url, data={}, tokenRequired = true) => {
    const headersData = {
        'Content-Type': 'application/json',
    }

    if (localStorage.token && url !== 'user/login' && tokenRequired === true) {
        headersData.access_token = localStorage.token
    }
    return await axios
        .delete(process.env.REACT_APP_API_URL + url, {
            headers: headersData,
            data: data
        })
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            if (error?.response?.status === 401 && localStorage.token) {
                setTimeout(() => {
                    localStorage.clear()
                    window.location.href = '/'
                }, 1000)
                return { success: false, message: '' }
            }
            if (error?.response?.status === 404) {
                return { success: false, message: error?.response?.data?.message ? error?.response?.data?.message : 'Api not found' }
            }
            if (error?.response?.data) {
                return error?.response?.data
            } else {
                return { success: false, message: 'Internal server error.' }
            }
        })
}